/* .test-container {
    background-image: url(../../assets/image/bann.png);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    

    
   
} */

.cards {
  display: flex;
  flex-direction: row;
}

/* Header загвар */
.test {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding: 12px 24px;
  align-items: center;
  color: #0e3889;
}
.energyM {
  width: 60%;
  text-align: center;
}
.headerListContainer li {
  float: left;
  list-style-type: none;
  margin-left: 18px;
  text-transform: uppercase;
  font-weight: 700;
  color: #0e3889 !important;

  cursor: pointer;
}

.siderContainer {
  background-color: #fff !important;
  box-shadow: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.contentContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.searchContainer {
  width: 40%;
  align-self: center;
}

.searchContainer input {
  border: 1px solid #0e3889;
  border-radius: 32px;
  width: 100%;
  padding: 8px 24px;
  color: #0e3889;
}

.searchContainer button {
  background-color: #0e3889;
  border-radius: 180px;
  border: none;
  width: 60px;
  height: 60px;
  cursor: pointer;
  position: absolute;
  /* z-index: 1; */
  right: -20px;
  top: -10px;
}

.searchContainer .searchIcon {
  color: #fff;
  font-size: 45px;
  font-weight: 900;
}

.searchContainer p {
  color: #0e3889;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Arial";
  font-size: 32px;
  padding-left: 20%;
}

/* Footer загвар */
/* .footerContainer {
    display: flex;
    flex-direction: column; */

.footerListContainer {
  display: flex;
  flex-direction: column;
}
.footerListContainer li {
  padding: 2px;
  list-style-type: none;
  margin-left: 12px;
  font-weight: 700;
  color: #0e3889;
  text-transform: uppercase;
  text-align: left;
  cursor: pointer;
}
.footerListContainer a {
  color: #0e3889;
}
.footerListContainer a:hover {
  color: #1757d6;
}
.bottomImage {
  width: 100%;
  margin-bottom: -350px;
  filter: opacity(0.8);
}

/* SVG*/
/* * {
    margin: 0;
    padding: 0;
}
body {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
} */

#circleTwo {
  transform: rotateY(-90deg);
  animation: connectionCircle 5s linear infinite;
  animation-fill-mode: forwards;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes connectionCircle {
  0% {
    transform: rotateY(-90deg);
  }
  100% {
    transform: rotateY(90deg);
  }
}
/* #circleTwo {
    animation: connectionCircle 5s linear infinite;
    animation-fill-mode: forwards;
    transform-origin: center;
    transform-box: fill-box;
}

@keyframes connectionCircle {
    0% {
        transform: rotateY(90deg);
    }
    100% {
        transform: rotateY(0deg);
    }
} */
#fans {
  animation: fans 5s linear infinite;
  transform-origin: center;
  transform-box: fill-box;
}
@keyframes fans {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
#tree {
  animation: tree ease-in-out 1.6 infinite alternate;
  transform-origin: bottom;
}
@keyframes tree {
  0% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(-20deg);
  }
}
/* #mongolia {
    animation: animateMongolia 15s linear infinite;
    animation-fill-mode: forwards;
    transform-origin: center;
    transform-box: fill-box;
}
@keyframes animateMongolia {
    0% {
        transform: rotateY(360deg) rotateX(10deg);
    }
    100% {
        transform: rotateY(0deg);
    }
} */
